import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";

import FlocknoteApp from "Flocknote";

import { getSentryEnvironment } from "./Utilities/Sentry/getSentryEnvironment";
import "./index.scss";

Sentry.init({
  dsn: "https://f58ae101bf0f6cad2520590daeaa153b@o4508342913466368.ingest.us.sentry.io/4508342915563520",
  integrations: [],
  environment: getSentryEnvironment(),
  ignoreErrors: [
    /^Non-Error promise rejection captured with value?/,
    /^cordova already defined?/,
  ],
  // for even more control over what to send, use beforeSend: return null to not send
  // beforeSend(event) {
  //   console.log(event);
  //   return event;
  // },
});

ReactDOM.render(<FlocknoteApp />, document.getElementById("fnReactHolder"));
